@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif !important;
}

a {
  color: #FFCC00 !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

/* .background_Image {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center;
  background-size: cover !important;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)), url("./assets/images/bg.svg");
} */

.background_color {
  background-color: #f8f8f8 !important;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
  display: none;
  scrollbar-gutter: stable both-edges;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 10px;
  border: 2px solid transparent;
}

/*--------------------- 
        Scrollbar css 
----------------------------------*/

.tooltip_custom {
  z-index: 9999999 !important;
}

/* .scrollable-content {
  margin-left: auto;
  margin-right: auto;
}
.scrollable-content:hover,
.scrollable-content:active,
.scrollable-content:focus {
  overflow-y: scroll;
} */

.background_gradient {
  background: linear-gradient(#0009, #0009);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}


/*--------------------- 
        MUI css 
----------------------------------*/
.sidebar_text span {
  font-size: 18px !important;
  line-height: 1 !important;
}

.active_sidebar {
  background: #fef9f8 !important;
}

.card_container {
  background: #fff;
  border-radius: 12px;
  padding: 40px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.table_select {
  width: 120px !important;

}

.table_select .MuiSelect-select {
  padding: 5px 10px !important;
  padding-right: 32px !important;
}

@media (max-width:600px) {
  .MuiDialog-paper {
    width: 100% !important;
  }
}

@media(max-width:600px) {
  .card_container {
    padding: 20px;
  }
}

.border_card {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.disable_button .Mui-disabled {
  background-color: #FFCC00 !important;
  color: #fff !important;
}

.category_table_row .MuiTableCell-root {
  font-weight: 600 !important;
}

.category_table_row {
  background: #FFCC0020 !important;
}

.specification_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}

.custome_tab_menu {
  background: #fff;
  border-radius: 10px;
  padding: 7px;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.table-hover {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.custome_tab_menu div {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width:600px) {
  .sidebar_text span {
    font-size: 16px !important;
  }
}

.box_shadow {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
}

.purchase_order_table .MuiTableCell-root {
  padding: 10px 15px !important;
}

.purchase_order_table .MuiTableRow-root:hover {
  box-shadow: unset !important;
  background: unset !important;
}

.input_select {
  position: absolute !important;
  top: 31px;
  left: 5px;
  width: fit-content !important;
}

.input_select fieldset {
  border: unset !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: unset !important;
}

.input_select .MuiSvgIcon-root {
  right: 3px !important;
  width: 0.8em !important;
}

.input_select .MuiSelect-select {
  padding: 6px 28px 6px 10px !important;
}

.select_input input {
  padding: 11px 20px 11px 60px !important;
}

/*--------------------- 
        MUI css 
----------------------------------*/

/*--------------------- 
        DateRange css 
----------------------------------*/
.date_range {
  width: 100% !important;
}

.date_range button {
  background: #fff;
  color: #000;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 13px 10px;
  text-align: start;
  font-size: 15px;
  width: 100%;
  /* box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25); */
}

.date_range_width button {
  width: unset !important;
  background: #FFCC00 !important;
  color: #fff !important;
}

.daterangepicker .ranges li.active {
  background-color: #FFCC00 !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  right: 20px !important;
  left: unset !important;
  display: none !important;
}

.show-calendar {
  right: 20px !important;
  overflow: scroll;
  height: 303px;
}

.applyBtn {
  background: #FFCC00 !important;
  border: 1px solid #FFCC00 !important;
}

.daterangepicker td.in-range {
  background-color: #FFCC0020 !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #FFCC00 !important;
}

.date_range_icon {
  padding: 4px;
}

.date_range_icon svg {
  font-size: 22px;
}

@media (max-width:600px) {
  .daterangepicker {
    right: 20px !important;
  }

  .date_range button {
    padding: 13px 10px;
    font-size: 14px;
  }

  .date_range_icon {
    padding: 3px;
    display: none !important;
  }

  .date_range_icon svg {
    font-size: 18px;
  }
}





/*--------------------- 
        DateRange css 
----------------------------------*/

/*--------------------- 
        Radix UI css 
----------------------------------*/
.contactDropDown,
.profileDropDownTrigger,
.notificationDropDownTrigger,
.chatDropDown {
  background: transparent;
  display: flex;
  border: unset !important;
  outline: unset !important;
}

[data-radix-popper-content-wrapper] {
  top: 9px !important;
  font-weight: 400;
}

.contactDropDownContent,
.profileDropDownContent,
.notificationDropDownContent,
.chatDropDownContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12);
  z-index: 1000;
  padding: 5px;
}

.contactDropDownMenu,
.profileDropDownMenu,
.notificationDropDownMenu,
.chatDropDownMenu {
  border: unset !important;
  outline: unset !important;
  font-size: 14px !important;
  cursor: pointer;
}

.profileDropDownMenu {
  padding: 12px
}

.profileDropDownMenu:hover {
  background: #FFCC0020;
}

.notificationDropDownMenu {
  padding: 10px;
}

.profileDropDownContent {
  padding: 8px !important;
}

.contactDropDownMenu,
.chatDropDownMenu {
  padding: 7px 10px;
}

.chatDropDownMenu:hover {
  background: #FFCC0020;
}

.contactDropDown .MuiButtonBase-root {
  width: 100%;
}

.contactDropDownMenu:hover {
  background: #FFCC0020;
}

.profileDropDown [data-radix-popper-content-wrapper] {
  left: 53px !important;
  top: 47px !important;
  width: 250px !important;
}

/*--------------------- 
        Radix UI css 
----------------------------------*/

.example {
  width: 80%;
  height: min-content;
  stroke: #333;
  padding: 4%;
  /* border: 1px solid #eaeef5; */
}

.cv_uploaded_image_trash {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cv_uploaded_image:hover .cv_uploaded_image_trash {
  background: #00000070;
}

.cv_uploaded_image svg {
  display: none;
  cursor: pointer;
}

.cv_uploaded_image:hover svg {
  display: block;
}

.otp_input div {
  justify-content: center !important;
  gap: 15px !important;
}

.order_details_table tr td:first-child,
.order_details_table tr th:first-child {
  text-align: start !important;
}

.order_details_table tr td:last-child,
.order_details_table tr th:last-child {
  text-align: end !important;
}

.counter_card svg {
  font-size: 30px;
  color: #ffc107;
}

@media (max-width: 600px) {
  .counter_card p {
    font-size: 14px !important;
  }

  .counter_card h6 {
    font-size: 18px !important;
  }

  .counter_card .MuiBox-root {}

  .counter_card svg {
    font-size: 22px;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

@media (min-width: 0px) {
  .css-12j9ukr-MuiGrid-root {
    flex-wrap: wrap;
  }
}